import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface BreederProps {
  side: 'top' | 'bottom' | 'left' | 'right';
  color: 'dark' | 'light';
  className?: string;
}

const Breeder: FunctionComponent<BreederProps> = ({ side, color, className }) => {
  return (
    <div
      className={classNames({
        'c-breeder': true,
        [`c-breeder--${side}`]: true,
        [`c-breeder--${color}`]: true,
        [`${className}`]: className,
      })}
    >
      <div className="c-breeder__animation" />
    </div>
  );
};

export default Breeder;
